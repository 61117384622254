import React from "react";
import { Button } from "../Button";
import "./styles.css";

const BlackCard = ({ image, title, text, onOpenContactUsModal, buttonLabel }) => {
  return (
    <div className="BlackCard">
      <div className="BlackCard__wrapper" style={{ backgroundImage: `url("${image.publicURL}")` }}>
        <div className="BlackCard__info">
          <div className="[ BlackCard__title ] [ Font_header Color_white sm:Font_smallHeader ]">{title}</div>
          <div className="[ BlackCard__text ] [ Font_mediumText Color_grey ]">{text}</div>
          <Button className="BlackCard__order" onClick={onOpenContactUsModal}>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { BlackCard };
