import { graphql } from "gatsby";
import React from "react";
import { CompletedProjects } from "../CompletedProjects";
import "./styles.css";

const HomeCompletedProjects = ({ title, examples }) => {
  return (
    <div id="completed-projects">
      <CompletedProjects title={title} examples={examples} />
    </div>
  );
};

HomeCompletedProjects.getLivePreviewData = ({ data, getAsset }) => ({
  completedProjects: {
    ...data.completedProjects,
    examples: data.completedProjects.examples.map(example => ({
      ...example,
      photo: getAsset(example.photo).url,
    })),
  },
});

const homeCompletedProjectsQuery = graphql`
  fragment HomeCompletedProjectsFragment on MarkdownRemarkFrontmatter {
    completedProjects {
      title
      examples {
        photo {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        title
        description
      }
    }
  }
`;

export { HomeCompletedProjects, homeCompletedProjectsQuery };
