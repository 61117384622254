import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import "./styles.css";

const HomeGeography = ({ background, alt, note, title, text }) => {
  return (
    <div className="[ HomeGeography ] [  ]">
      <div className="HomeGeography_MapContainer">
        <Image className="[ HomeGeography__background ]" image={background} alt={alt} />
        <div className="[ HomeGeography__note ] [ Font_smallText Color_darkGrey ]">{note}</div>
      </div>

      <div className="[ HomeGeography__content ] [ Content ]">
        <div className="HomeGeography__info">
          <div className="HomeGeography__title [ Font_header sm:Font_smallHeader Color_black ]">{title}</div>
          <div className="HomeGeography__text [ Font_mediumText md:Font_mediumText  Color_darkGrey ]">{text}</div>
        </div>
      </div>
    </div>
  );
};

HomeGeography.getLivePreviewData = ({ data, getAsset }) => ({
  geography: {
    ...data.geography,
    background: getAsset(data.geography.background).url,
  },
});

const homeGeographyQuery = graphql`
  fragment HomeGeographyFragment on MarkdownRemarkFrontmatter {
    geography {
      background {
        childImageSharp {
          fluid(maxWidth: 1472, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
      note
      title
      text
    }
  }
`;

export { HomeGeography, homeGeographyQuery };
