import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import "./styles.css";

const HomeAdvantages = ({ background, alt, title, advantages }) => {
  return (
    <div className="HomeAdvantages">
      <div className="HomeAdvantages__bg HomeAdvantages__bg--mobile">
        <Image className="HomeAdvantages__background" image={background} alt={alt} />
      </div>
      <div className="[ HomeAdvantages__wrapper ] [ Content md:Content_medium sm:Content_medium ]">
        <div className="HomeAdvantages__bg HomeAdvantages__bg--desktop">
          <Image className="HomeAdvantages__background" image={background} alt={alt} />
        </div>
        <div className="HomeAdvantages__content">
          <div className="HomeAdvantages__exp">
            <div className="HomeAdvantages__expNumber">{title.numberOfYears}</div>
            <div className="HomeAdvantages__expText">
              <span className="HomeAdvantages__expTextTopLine">{title.topLine}</span>
              <span className="HomeAdvantages__expTextBottomLine">{title.bottomLine}</span>
            </div>
          </div>
          {advantages.map(advantage => (
            <div key={advantage.title} className="HomeAdvantages__text">
              <div className="[ HomeAdvantages__advantageTitle ] [ Font_mediumTextBold ]">{advantage.title}</div>
              <div className="[ HomeAdvantages__advantageDescription ] [ Font_regularText Color_darkGrey ]">
                {advantage.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

HomeAdvantages.getLivePreviewData = ({ data, getAsset }) => ({
  advantages: {
    ...data.advantages,
    background: getAsset(data.advantages.background).url,
  },
});

const homeAdvantagesQuery = graphql`
  fragment HomeAdvantagesFragment on MarkdownRemarkFrontmatter {
    advantages {
      background {
        childImageSharp {
          fluid(maxWidth: 1248) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
      title {
        numberOfYears
        topLine
        bottomLine
      }
      advantages {
        title
        description
      }
    }
  }
`;

export { HomeAdvantages, homeAdvantagesQuery };
