import { graphql } from "gatsby";
import React, { useCallback, useState } from "react";
import Helmet from "react-helmet";
import { ContactUsModal } from "../components/ContactUsModal";
import { Home3dRender } from "../components/Home3dRender";
import { HomeAdvantages } from "../components/HomeAdvantages";
import { HomeCompletedProjects } from "../components/HomeCompletedProjects";
import { HomeGeography } from "../components/HomeGeography";
import { HomeHero } from "../components/HomeHero";
import { HomePartners } from "../components/HomePartners";
import { HomePartnership } from "../components/HomePartnership";
import { HomeServices } from "../components/HomeServices/HomeServices";
import { HomeSolutions } from "../components/HomeSolutions";
import { Layout } from "../components/Layout";

const IndexPageTemplate = ({ layout, ...props }) => {
  const [isOpenContactUsModal, setIsOpenContactUsModal] = useState();
  const handleOpenContactUsModal = useCallback(() => setIsOpenContactUsModal(true), []);
  const handleCloseContactUsModal = useCallback(() => setIsOpenContactUsModal(false), []);

  return (
    <Layout {...layout} onOpenContactUsModal={handleOpenContactUsModal} lang={props.layoutId}>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <HomeHero {...props.hero} onOpenContactUsModal={handleOpenContactUsModal} />
      <HomeSolutions {...props.solutions} lang={props.layoutId} />
      <HomePartnership {...props.partnership} onOpenContactUsModal={handleOpenContactUsModal} />
      <Home3dRender {...props.render} onOpenContactUsModal={handleOpenContactUsModal} />
      <HomeServices {...props.services} />
      <HomeGeography {...props.geography} />
      <HomeCompletedProjects {...props.completedProjects} />
      <HomePartners {...props.partners} />
      <HomeAdvantages {...props.advantages} />

      <ContactUsModal isOpen={isOpenContactUsModal} onClose={handleCloseContactUsModal} {...layout.contactUsModal} />
    </Layout>
  );
};

IndexPageTemplate.getLivePreviewData = props => ({
  ...Layout.getLivePreviewData(props),
  ...HomeHero.getLivePreviewData(props),
  ...HomeSolutions.getLivePreviewData(props),
  ...HomePartnership.getLivePreviewData(props),
  ...Home3dRender.getLivePreviewData(props),
  ...HomeServices.getLivePreviewData(props),
  ...HomeGeography.getLivePreviewData(props),
  ...HomeCompletedProjects.getLivePreviewData(props),
  ...HomePartners.getLivePreviewData(props),
  ...HomeAdvantages.getLivePreviewData(props),
});

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return <IndexPageTemplate layout={data.layout.frontmatter} {...frontmatter} />;
};

const pageQuery = graphql`
  query IndexPageTemplate($id: String!, $layoutId: String!) {
    layout: markdownRemark(frontmatter: { id: { eq: $layoutId } }) {
      frontmatter {
        ...LayoutFragment
        ...ContactUsModalFragment
      }
    }

    markdownRemark(id: { eq: $id }) {
      frontmatter {
        layoutId
        seo {
          title
          description
          keywords
        }

        ...HomeHeroFragment
        ...HomeSolutionsFragment
        ...HomePartnershipFragment
        ...Home3dRenderFragment
        ...HomeServicesFragment
        ...HomeGeographyFragment
        ...HomeCompletedProjectsFragment
        ...HomePartnersFragment
        ...HomeAdvantagesFragment
      }
    }
  }
`;

export default IndexPage;

export { pageQuery, IndexPageTemplate };
