import cn from "classnames";
import React from "react";
import { Button } from "../../Button";
import { Image } from "../../Image";

const Partnership = ({
  background,
  data: { title, weNeed, number, months, text, note, buttonLabel, image, alt, subtitle },
  className,
  onOpenContactUsModal,
}) => {
  return (
    <React.Fragment>
      <img className={cn(`HomePartnership__background${className}`)} src={background} alt="" />
      <div
        className={cn(
          "HomePartnership__Item",
          `[ HomePartnership__${className} ]`,
          "Content md:Content_small sm:Content_medium"
        )}
      >
        <div className="HomePartnership__ImageContainer">
          <Image className="HomePartnership__Image" image={image} alt={alt} />
        </div>

        <div className="HomePartnership__ItemInfo">
          {subtitle && (
            <div className="HomePartnership__Subtitle [ Font_smallHeader sm:Font_mediumTextBold   Color_middleGrey ]">
              {subtitle}
            </div>
          )}

          <div className="HomePartnership__ItemTitle [ Font_header sm:Font_smallHeader  Color_black ] ">{title}</div>

          {weNeed && number && months && (
            <div className="HomePartnership__HowLong">
              <div className="HomePartnership__Number [ Color_black ]">{number}</div>
              <div>
                <div className="HomePartnership__WeNeed [ Color_middleGrey  ]">{weNeed}</div>
                <div className="HomePartnership__Months">{months}</div>
              </div>
            </div>
          )}

          <div className="HomePartnership__Text [ Font_bigText sm:Font_mediumText Color_black ] ">{text}</div>

          {note && <div className="[ HomePartnership__Note ] [ Font_smallText Color_darkGrey ] ">{note}</div>}

          <Button className="HomePartnership__Callback" onClick={onOpenContactUsModal}>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export { Partnership };
