import cn from "classnames";
import { graphql, Link } from "gatsby";
import React from "react";
import { Image } from "../Image";
import "./styles.css";

const HomeSolutions = ({ title, solutions, lang }) => {
  solutions = [
    { ...solutions[0], href: "/shop-equipment", class: "shopEquipment" },
    { ...solutions[1], href: "/cash-area-equipment", class: "equipmentForCashZones" },
    { ...solutions[2], href: "/ceramic-tile-holder", class: "shelvesForCeramicTiles" },
    { ...solutions[3], href: "/metal-constructions", class: "metalFrames" },
    { ...solutions[4], href: "/self-checkout", class: "selfCheckout" },
  ];

  return (
    <div className="[ HomeSolutions ] [ Content ]">
      <div className="[ HomeSolutions__title ] [ Font_smallHeader sm:Font_mediumTextBold ]">{title}</div>

      <div className="[ HomeSolutions__solutionsContainer ]">
        <div className="[ HomeSolutions__solutions ]">
          {solutions.map(solution => (
            <Link
              to={lang === "en" ? solution.href + "-en" : solution.href}
              key={solution.title}
              className={cn("HomeSolutions__solution", `HomeSolutions__solution_${solution.class}`)}
            >
              <div className="HomeSolutions__solutionContent">
                <div className="[ Font_smallHeader sm:Font_mediumTextBold Color_black ]">{solution.title}</div>
                <div className="[ HomeSolutions__description ] [ Font_regularText Color_darkGrey ]">
                  {solution.description}
                </div>
              </div>
              <Image className="[ HomeSolutions__background ]" image={solution.background} alt={solution.alt} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

HomeSolutions.getLivePreviewData = ({ data, getAsset }) => ({
  solutions: {
    ...data.solutions,
    solutions: data.solutions.solutions.map(solution => ({
      ...solution,
      background: getAsset(solution.background).url,
    })),
  },
});

const homeSolutionsQuery = graphql`
  fragment HomeSolutionsFragment on MarkdownRemarkFrontmatter {
    solutions {
      title
      solutions {
        background {
          childImageSharp {
            fluid(maxWidth: 1472) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        title
        description
      }
    }
  }
`;

export { HomeSolutions, homeSolutionsQuery };
