import React from "react";

import "./styles.css";
import { graphql } from "gatsby";

const HomeServices = ({ title, services }) => {
  return (
    <div id="services" className="[ HomeServices ] [ Content sm:Content_medium ]">
      <div className="[ HomeServices__title ] [ Font_smallHeader ]">{title}</div>
      <div className="HomeServices__container">
        <div className="HomeServices__wrapper">
          {services.map(({ icon, title, text }) => (
            <div key={title} className="HomeServices__item">
              <img className="HomeServices__img" src={icon.publicURL} alt={`Иконка "${title}"`} />
              <div className="[ HomeServices__case ] [ Font_mediumTextBold ]">{title}</div>
              <div className="[ HomeServices__description ] [ Font_regularText Color_darkGrey ]">{text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

HomeServices.getLivePreviewData = ({ data, getAsset }) => ({
  services: {
    ...data.services,
    services: data.services.services.map(service => ({
      ...service,
      icon: {
        publicURL: getAsset(service.icon).url,
      },
    })),
  },
});

const homeServicesQuery = graphql`
  fragment HomeServicesFragment on MarkdownRemarkFrontmatter {
    services {
      title
      services {
        icon {
          publicURL
        }
        title
        text
      }
    }
  }
`;

export { HomeServices, homeServicesQuery };
