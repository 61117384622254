import { graphql } from "gatsby";
import React from "react";
import { BlackCard } from "../BlackCard/BlackCard";
import "./styles.css";

const Home3dRender = ({ title, text, buttonLabel, image, onOpenContactUsModal }) => {
  return (
    <div className="[ Home3dRender ] [ Content ]" id="3d-render">
      <BlackCard
        title={title}
        text={text}
        buttonLabel={buttonLabel}
        image={image}
        onOpenContactUsModal={onOpenContactUsModal}
      />
    </div>
  );
};

Home3dRender.getLivePreviewData = ({ data, getAsset }) => ({
  render: {
    ...data.render,
    image: {
      publicURL: getAsset(data.render.image).url,
    },
  },
});

const home3dRenderQuery = graphql`
  fragment Home3dRenderFragment on MarkdownRemarkFrontmatter {
    render {
      title
      text
      buttonLabel
      image {
        publicURL
      }
    }
  }
`;

export { Home3dRender, home3dRenderQuery };
