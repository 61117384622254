import { graphql } from "gatsby";
import React from "react";
import { Partnership } from "./PartnerShip";
import "./styles.css";

const HomePartnership = ({ backgroundLeft, backgroundRight, chainStores, newShops, onOpenContactUsModal }) => {
  let partnerships = [
    { background: backgroundLeft.publicURL, data: chainStores, namePartner: "ChainStores" },
    { background: backgroundRight.publicURL, data: newShops, namePartner: "NewShops" },
  ];
  return (
    <div className="HomePartnership">
      {partnerships.map(({ background, data, namePartner }) => (
        <Partnership
          key={data.title}
          background={background}
          data={data}
          className={namePartner}
          onOpenContactUsModal={onOpenContactUsModal}
        />
      ))}
    </div>
  );
};

HomePartnership.getLivePreviewData = ({ data, getAsset }) => ({
  partnership: {
    ...data.partnership,
  },
});

const homePartnershipQuery = graphql`
  fragment HomePartnershipFragment on MarkdownRemarkFrontmatter {
    partnership {
      backgroundLeft {
        publicURL
      }
      backgroundRight {
        publicURL
      }

      chainStores {
        image {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        title
        weNeed
        number
        months
        text
        note
        buttonLabel
      }

      newShops {
        image {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        subtitle
        title
        text
        buttonLabel
      }
    }
  }
`;

export { HomePartnership, homePartnershipQuery };
