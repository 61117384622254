import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Button } from "../Button";
import "./styles.css";

const HomeHero = ({ background, backgroundMobile, subtitle, title, text, buttonLabel, onOpenContactUsModal }) => {
  return (
    <div className="HomeHero">
      <div className="[ Content sm:Content_medium ]">
        <div className="HomeHero__content">
          <div className="[ Font_smallHeader sm:Font_mediumTextBold Color_middleGrey ]">{subtitle}</div>
          <div className="[ HomeHero__title ] [ Font_superHeader sm:Font_smallHeader ]">{title}</div>
          <div className="[ HomeHero__text ] [ Font_bigText sm:Font_regularText ]">{text}</div>
          <Button className="HomeHero__button" onClick={onOpenContactUsModal}>
            {buttonLabel}
          </Button>
        </div>
      </div>

      <div className="HomeHero__backgroundContainer">
        <Img
          className="HomeHero__background"
          fluid={[
            background.childImageSharp.fluid,
            { ...backgroundMobile.childImageSharp.fluid, media: "(max-width: 1200px)" },
          ]}
        />
      </div>
    </div>
  );
};

HomeHero.getLivePreviewData = ({ data, getAsset }) => ({
  hero: {
    ...data.hero,
    background: getAsset(data.hero.background).url,
    backgroundMobile: getAsset(data.hero.backgroundMobile).url,
  },
});

const homeHeroQuery = graphql`
  fragment HomeHeroFragment on MarkdownRemarkFrontmatter {
    hero {
      background {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      backgroundMobile {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      subtitle
      title
      text
      buttonLabel
    }
  }
`;

export { HomeHero, homeHeroQuery };
