import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import "./styles.css";

const HomePartners = ({ partners }) => {
  const splitIndex = Math.round(partners.length / 2);

  return (
    <div className="HomePartners">
      {/* First line */}
      <div className="HomePartners__line">
        {partners.slice(0, splitIndex).map((partner, index) => (
          <div key={index} className="HomePartners__item">
            <Image className="HomePartners__img" image={partner} alt="" />
          </div>
        ))}
      </div>
      {/* ..First line */}

      {/* Second line */}
      <div className="HomePartners__line">
        {partners.slice(splitIndex).map((partner, index) => (
          <div key={index} className="HomePartners__item">
            <Image className="HomePartners__img" image={partner} alt="" />
          </div>
        ))}
      </div>
      {/* ..Second line */}
    </div>
  );
};

HomePartners.getLivePreviewData = ({ data, getAsset }) => ({
  partners: {
    ...data.partners,
    partners: data.partners.partners.map(partner => getAsset(partner).url),
  },
});

const homePartnersQuery = graphql`
  fragment HomePartnersFragment on MarkdownRemarkFrontmatter {
    partners {
      partners {
        childImageSharp {
          fluid(maxWidth: 288) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export { HomePartners, homePartnersQuery };
